import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Index from "../Index/Index";
//import PopUpNotificaciones from "../PopUp/PopUp";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import { Outlet } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { Tooltip } from "@mui/material";

const Structure = () => {
  return (
    <section className="structure">
      <Header />
    <Tooltip
    title="Contáctanos por Whatsapp"
    >
      <div 
      onClick={() => window.open("https://api.whatsapp.com/send?phone=5216143815993", "_blank")}
      className="whatsapp-pop">
        <FontAwesomeIcon
          style={{ color: "white", fontSize: "43px" }}
          icon={faWhatsapp}
        />
      </div>
      </Tooltip>

      <Outlet />
      <Footer />
    </section>
  );
};

export default Structure;
